window.app.service('DatabaseService', ['AuthService', 'DBFactory', function (AuthService, DBFactory) {
  class Database {
    constructor() {}

    static getOwn(database: string) {
      let user_id = AuthService.userId;
      if (user_id) return DBFactory.then((ds) => ds.db).then((db) => db.valuesByIndex(database, 'user_id', ydn.db.KeyRange.only(user_id), 2000))
      else return Promise.resolve([]);
    }

    static getLocalPagination(datatable, page, column, reverse, extra) {
      return this.getOwn(datatable).then(entries => {
        if (entries && entries.length) {
          let data = entries;
          let totalCount = Math.ceil(entries.length / 10);
          if (page > totalCount) page = 1;
          let start = Math.min((page - 1) * 10, entries.length);

          let sortedData = entries.sort((a, b) => this.sortItems(a,b, column, reverse))
                                  .filter(item => extra && extra.trueOptions  ?  extra.trueOptions.find(o  => o === item[extra.field]) : true)
                                  .filter(item => extra && extra.falseOptions ? !extra.falseOptions.find(o => o === item[extra.field]) : true)
                                  .filter((item, index) => index >= start && index < 10 + start);
          return { data, page, totalCount, sortedData };
        }
      });
    }

    static sortItems(a, b, column, reverse) {
      if (a[column] instanceof Date && b[column]instanceof Date || 
          typeof a[column] === 'string' && Date.parse(a[column]) &&
          typeof b[column] === 'string' && Date.parse(b[column])) return reverse ? +new Date(b[column]) - +new Date(a[column]) : +new Date(a[column]) - +new Date(b[column]);
      else return reverse ? b[column] - a[column] : a[column] - b[column];
    }

  }
  return Database;
}]);

